import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import MenuTrigger from "../../components/Button/MenuTrigger";

const Music = () => {
  const [openMenu, setOpenManu] = useState(false);
  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate("/dev", {
      state: "music"
    })
  }


  return (
    <div className="page music">
      <MenuTrigger openMenu={openMenu} onClick={() => setOpenManu(!openMenu)} />
      <div className="container-fluid">
        <div className="content-top">
          <div className="infos" data-aos="fade-left">
            <div className="title">Desentralized Streaming Platform</div>
            <h1 className="fitur-name">Asix Music </h1>
            <p className="desc">
              Media Streaming Facility capable of streaming high quality music
              at 360 kbps bitrate, HD videos or podcast.
            </p>
            <div className="actions floating">
              <button onClick={handleNavigate} className="btn-action-primary">OPEN</button>
            </div>
          </div>
          <div className="copyright fix">
            <p>Copyright © 2022 Asix+ All Rights Reserved</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Music;
