import "./index.scss"
import { useEffect, useState } from 'react';
import AsixMusic from "../../assets/logos/asix-music.png"
import AsixPlus from "../../assets/logos/asix-plus.png"
import PasarNFT from "../../assets/logos/pasar-nft.png"
import Earn from "../../assets/logos/earn.png"
import Nusantara from "../../assets/logos/nusantara-verse.png"
import { Link } from "react-router-dom";

const LandingPage = () => {
    const hasWindow = typeof window !== 'undefined';
    const width = hasWindow ? window.innerWidth : null;
    const height = hasWindow ? window.innerHeight : null;

    const [yAsixMusic, setyAsixMusic] = useState(-150)
    const [xAsixMusic, setxAsixMusic] = useState(-10)
    const [yAsixPlus, setyAsixPlus] = useState(1)
    const [xAsixPlus, setxAsixPlus] = useState(1)
    const [yPasarNFT, setyPasarNFT] = useState(1)
    const [xPasarNFT, setxPasarNFT] = useState(1)

    const [classMusic, setclassMusic] = useState(1)
    const [classAsixPlus, setclassAsixPlus] = useState(1)
    const [classPasarNFT, setclassPasarNFT] = useState(1)
    const [classEarn, setclassEarn] = useState(1)
    const [classNusantara, setclassNusantara] = useState(1)
    console.log(width)
    console.log("window.outerWidth", window.outerWidth)

    useEffect(() => {
        setTimeout(() => {
            if (hasWindow) {
                if (height > width) {
                    setyAsixMusic(height / 2)
                    setxAsixMusic(height / width)
                    setyAsixPlus(height / 2)
                    setxAsixPlus(height / width)

                    setyPasarNFT(height / 2)
                    setxPasarNFT(height / width)
                    setclassMusic(`translate(${6}vw, ${-145}%)`)
                    setclassAsixPlus(`translate(${-5}vw, ${-105}%)`)
                    setclassPasarNFT(`translate(${.1}vw, ${-50}%)`)
                    setclassEarn(`translate(${8}vw, ${-130}%)`)
                    setclassNusantara(`translate(${-14}vw, ${-20}%)`)
                    if (width >= 320 && width < 376) {
                        setclassMusic(`translate(${6}vw, ${-159}%)`)
                        setclassPasarNFT(`translate(${.1}vw, ${-57}%)`)
                    }
                    if (width > 376 && width < 414) {
                        setclassMusic(`translate(${3}vw, ${-105}%)`)
                        setclassPasarNFT(`translate(${-1.3}vw, ${17}%)`)
                        setclassAsixPlus(`translate(${-5}vw, ${-55}%)`)
                        setclassEarn(`translate(${8}vw, ${-60}%)`)
                        setclassNusantara(`translate(${-12}vw, ${45}%)`)
                    }
                    if (width >= 360 && width < 414 && height >= 740) {
                        setclassMusic(`translate(${6}vw, ${-165}%)`)
                        setclassPasarNFT(`translate(${.1}vw, ${-50}%)`)
                    }
                    if (width >= 414 && width < 576) {
                        setclassMusic(`translate(${6}vw, ${-165}%)`)

                    }
                    if (width >= 576) {
                        setclassMusic(`translate(${6}vw, ${-163}%)`)
                        setclassAsixPlus(`translate(${-5}vw, ${-105}%)`)
                        setclassPasarNFT(`translate(${.1}vw, ${-55}%)`)
                    }
                    if (width === 384 && height === 640) {
                        setclassMusic(`translate(${6}vw, ${-150}%)`)
                        setclassPasarNFT(`translate(${.1}vw, ${-60}%)`)
                        setclassAsixPlus(`translate(${-5}vw, ${-105}%)`)
                        setclassEarn(`translate(${8}vw, ${-100}%)`)
                        setclassNusantara(`translate(${-14}vw, ${-30}%)`)
                    }
                    if (width === 414 && height === 896) {
                        setclassMusic(`translate(${1}vw, ${-175}%)`)
                        setclassPasarNFT(`translate(${-3}vw, ${-40}%)`)
                        setclassAsixPlus(`translate(${-5}vw, ${-105}%)`)
                        setclassEarn(`translate(${16}vw, ${-130}%)`)
                        setclassNusantara(`translate(${-8}vw, ${-20}%)`)
                    }
                }
                if (height < width) {
                    setyAsixMusic(height / 2)
                    setxAsixMusic(height / width)

                    setyAsixPlus(height / 2)
                    setxAsixPlus(height / width)

                    setyPasarNFT(height / 2)
                    setxPasarNFT(height / width)
                    setclassMusic(`translate(${4.5}vw, ${-170}%)`)
                    setclassAsixPlus(`translate(${4.5}vw, ${-170}%)`)
                    setclassPasarNFT(`translate(${-1}vw, ${-50}%)`)
                    if (width >= 772 && width < 992) {
                        console.log(width)
                        setclassMusic(`translate(${5}vw, ${-162}%)`)
                        setclassAsixPlus(`translate(${-5}vw, ${-115}%)`)
                        setclassPasarNFT(`translate(${-1}vw, ${-55}%)`)
                        setclassEarn(`translate(${7}vw, ${-125}%)`)
                        setclassNusantara(`translate(${-15}vw, ${-20}%)`)
                    }
                    if (width >= 992 && width < 1024) {
                        console.log(width)
                        setclassMusic(`translate(${-3.5}vw, ${-192}%)`)
                        setclassAsixPlus(`translate(${-5}vw, ${-105}%)`)
                        setclassPasarNFT(`translate(${-5}vw, ${-25}%)`)
                        setclassEarn(`translate(${15}vw, ${-145}%)`)
                        setclassNusantara(`translate(${-5}vw, ${-20}%)`)
                    }
                    if (width >= 1024 && width < 1189) {
                        console.log(width)
                        setclassMusic(`translate(${-1.3}vw, ${-182}%)`)
                        setclassAsixPlus(`translate(${-5}vw, ${-105}%)`)
                        setclassPasarNFT(`translate(${-3}vw, ${-30}%)`)
                        setclassEarn(`translate(${15}vw, ${-145}%)`)
                        setclassNusantara(`translate(${-8}vw, ${-10}%)`)
                    }
                    if (width >= 1189 && width < 1200) {
                        console.log(width)
                        setclassMusic(`translate(${3}vw, ${-182}%)`)
                        setclassAsixPlus(`translate(${-5}vw, ${-105}%)`)
                        setclassPasarNFT(`translate(${-2}vw, ${-36}%)`)
                        setclassEarn(`translate(${9}vw, ${-135}%)`)
                        setclassNusantara(`translate(${-10}vw, ${-10}%)`)
                    }
                    if (width >= 1200 && width < 1400) {
                        console.log(width)
                        setclassMusic(`translate(${4}vw, ${-175}%)`)
                        setclassAsixPlus(`translate(${-5}vw, ${-105}%)`)
                        setclassPasarNFT(`translate(${-2}vw, ${-50}%)`)
                        setclassEarn(`translate(${8}vw, ${-120}%)`)
                        setclassNusantara(`translate(${-13}vw, ${-20}%)`)
                    }
                    if (width >= 1400) {
                        setclassMusic(`translate(${4.5}vw, ${-170}%)`)
                        setclassAsixPlus(`translate(${-5}vw, ${-105}%)`)
                        setclassPasarNFT(`translate(${-1}vw, ${-40}%)`)
                        setclassEarn(`translate(${8}vw, ${-120}%)`)
                        setclassNusantara(`translate(${-13}vw, ${-10}%)`)
                    }
                }
            }
        }, 100);
    }, [width, height, hasWindow])

    return (
        <div className='landing-page'>
            <div className="wrapper">
                <div className="ft-box">
                    <Link to="/music" className='music'
                        style={{
                            top: yAsixMusic,
                            right: xAsixMusic,
                            transform: classMusic
                        }}
                    >
                        <img src={AsixMusic} alt="" className='music-animate' />
                    </Link>
                </div>
            </div>
            <div className="wrapper">
                <div className="ft-box">
                    <Link to="/pasar-nft" className='pasar-nft'
                        style={{
                            top: yAsixMusic,
                            right: xAsixMusic,
                            transform: classPasarNFT
                        }}
                    >
                        <img src={PasarNFT} alt="" className='music-animate' />
                    </Link>
                </div>
            </div>
            <div className="wrapper">
                <div className="ft-box">
                    <Link to="/asix+" className='asix-plus'
                        style={{
                            top: yAsixMusic,
                            right: xAsixMusic,
                            transform: classAsixPlus
                        }}
                    >
                        <img src={AsixPlus} alt="" className='music-animate' />
                    </Link>
                </div>
            </div>
            <div className="wrapper">
                <div className="ft-box">
                    <Link to="/earn" className='earn'
                        style={{
                            top: yAsixMusic,
                            right: xAsixMusic,
                            transform: classEarn
                        }}
                    >
                        <img src={Earn} alt="" className='music-animate' />
                    </Link>
                </div>
            </div>
            <div className="wrapper">
                <div className="ft-box">
                    <Link to="nusantara-verse" className='nusantara'
                        style={{
                            top: yAsixMusic,
                            right: xAsixMusic,
                            transform: classNusantara
                        }}
                    >
                        <img src={Nusantara} alt="" className='music-animate' />
                    </Link>
                </div>
            </div>
        </div >
    )
}

export default LandingPage