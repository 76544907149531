import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import MenuTrigger from "../../components/Button/MenuTrigger";

const PasarNFT = () => {
  const [openMenu, setOpenManu] = useState(false);
  const navigate = useNavigate();
  return (
    <div className="page pasar-nft">
      <MenuTrigger openMenu={openMenu} onClick={() => setOpenManu(!openMenu)} />
      <div className="container-fluid">
        <div className="content-top">
          <div className="infos" data-aos="fade-left">
            <div className="title">The New Way</div>
            <h1 className="fitur-name">Pasar NFT </h1>
            <p className="desc">
              Pasar NFT is a platform containing audio, video, photos or images
              in digital format on Blockchain supported by creators (artists,
              aggregators, record companies, film companies, animation studios
              and various institutions) and audiences around the world.
            </p>
            <div className="actions floating">
              <button
                onClick={() => navigate("/dev", { state: "pasar-nft" })}
                className="btn-action-primary"
              >
                OPEN
              </button>
            </div>
          </div>
          <div className="copyright fix">
            <p>Copyright © 2022 Asix+ All Rights Reserved</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PasarNFT;
