import { BsCheckCircleFill, BsCheck2All,BsThreeDots,BsCircleFill } from "react-icons/bs"
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper';

const RoadMap = ({ data }) => {
  return (
    <div className="container-fluid py-5">
      <h2 className="heading-roadmap">Asix+ Roadmap</h2>
      <Swiper
        hashNavigation={{
          watchState: true,
        }}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[Pagination, Navigation]}
        spaceBetween={20}
        slidesPerView={1}
        onSlideChange={() => console.log('slide change')}
        onSwiper={(swiper) => console.log(swiper)}

        breakpoints={{
          640: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 3,
            spaceBetween: 40,
          },
          1024: {
            slidesPerView: 4,
            spaceBetween: 50,
          },
        }}
      >
        {
          data?.map(item => (
            <SwiperSlide key={item._id}>
              <div className="item-roadmap">
                <div className="title">
                  <span>{item.name}</span>
                </div>
                <div className="circle">
                  {
                    item.status === "DONE" ? <BsCheck2All /> : <BsThreeDots />
                  }
                </div>
                {item?.phases?.map(ph => (
                  <div className="list" key={ph._id}>
                    <div className="icon">
                      {ph.status === "DONE" ? <BsCheckCircleFill color='#70BF6B' /> : <BsCircleFill /> }
                    </div>
                    <span>{ph.name}</span>
                  </div>
                ))}
                {/* <div className="list">
                  <div className="icon">
                    <BsCheckCircleFill />
                  </div>
                  <span>AUDIT FINANCE ( SOLIDITY FIANANCE)</span>
                </div>
                <div className="list">
                  <div className="icon">
                    <BsCheckCircleFill />
                  </div>
                  <span>LISTING ON DEX PANCAKESWAP</span>
                </div>
                <div className="list">
                  <div className="icon">
                    <BsCheckCircleFill />
                  </div>
                  <span>LISTING COINGECKO</span>
                </div> */}
              </div>
            </SwiperSlide>
          ))
        }
      </Swiper>
    </div>
  )
}

export default RoadMap