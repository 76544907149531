import "aos/dist/aos.css";
import 'swiper/css';
import "./App.scss";

import AOS from "aos";
import { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import AsixWorld from "./pages/AsixWorld";
import Earn from "./pages/Earn";
import LandingPage from "./pages/LandingPage";
import MainAsix from "./pages/MainAsix";
import Music from "./pages/Music";
import PasarNFT from "./pages/PasarNFT";
import UnderDev from "./pages/UnderDev";
import PageNotFound from "./pages/PageNotFound";

function App() {
  const [isLoading, setIsLoading] = useState(true)
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
    setTimeout(() => {
      setIsLoading(false)
    }, 2000);
  }, []);

  return (
    <BrowserRouter>
      {/* {isLoading && <MainLoader />} */}
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/asix+" element={<MainAsix />} />
        <Route path="/music" element={<Music />} />
        <Route path="/pasar-nft" element={<PasarNFT />} />
        <Route path="/nusantara-verse" element={<AsixWorld />} />
        <Route path="/earn" element={<Earn />} />
        <Route path="/dev" element={<UnderDev />} />
        <Route path="/*" element={<PageNotFound />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
