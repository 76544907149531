import React, { useState } from "react";
import MenuTrigger from "../../components/Button/MenuTrigger";

const Earn = () => {
  const [openMenu, setOpenManu] = useState(false);
  const handleNavigate = () => {
    window.location.href = "http://earn.asixplus.io"
  }
  return (
    <div className="page earn">
      <MenuTrigger openMenu={openMenu} onClick={() => setOpenManu(!openMenu)} />
      <div className="container-fluid">
        <div className="content-top">
          <div className="infos pb-5" data-aos="fade-left">
            <h1 className="fitur-name">Asix Earn </h1>
            <p className="desc">
              Asix+ Earn is a complete suite of financial products you can use to increase your crypto holdings.
            </p>
            <div className="actions floating">
              <button
                onClick={handleNavigate}
                className="btn-action-primary mt-4"
              >
                VEXSTAKING
              </button>
            </div>
          </div>
          <div className="copyright fix">
            <p>Copyright © 2022 Asix+ All Rights Reserved</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Earn;
