import Binance from '../../assets/binance.png'
// import Hotbit from '../../assets/hotbit-partner.png'
import Pancake from '../../assets/pancake-partner.png'
import Dextools from '../../assets/dextools-partner.png'
import CmcParner from '../../assets/cmc-partner.png'
import Indodax from '../../assets/indodax-partner.png'
const OurTeam = () => {
  return (
    <div className='container'>
      <div className='row justify-content-center'>
        <div className='col-6 col-md-4 col-lg-2 mb-4'>
          <a
            href='https://bscscan.com/token/0x2bF4BE7C4520C41d012EB09a034179E03b898534'
            className='partner-ship-link'
            target='_blank'
            rel='noopener noreferrer'
          >
            <img className='img-fluid' src={Binance} alt='' />
          </a>
        </div>
        <div className='col-6 col-md-4 col-lg-2 mb-4'>
          <a
            href='https://coinmarketcap.com/currencies/asix/'
            className='partner-ship-link'
            target='_blank'
            rel='noopener noreferrer'
          >
            <img className='img-fluid' src={CmcParner} alt='' />
          </a>
        </div>
        <div className='col-6 col-md-4 col-lg-2 mb-4'>
          <a
            href='https://www.dextools.io/app/bnb/pair-explorer/0xd059dca0ad8bc51803fd487e6ed8244c1fdad97e'
            className='partner-ship-link'
            target='_blank'
            rel='noopener noreferrer'
          >
            <img className='img-fluid' src={Dextools} alt='' />
          </a>
        </div>
        <div className='col-6 col-md-4 col-lg-2 mb-4'>
          <span className='partner-ship-link'>
            <img className='img-fluid' src={Indodax} alt='' />
          </span>
        </div>
        {/* <div className='col-6 col-md-4 col-lg-2 mb-4'>
          <a
            href='https://www.hotbit.io/exchange?symbol=ASIXPLUS_nUSD'
            className='partner-ship-link'
            target='_blank'
            rel='noopener noreferrer'
          >
            <img className='img-fluid' src={Hotbit} alt='' />
          </a>
        </div> */}
        <div className='col-6 col-md-4 col-lg-2 mb-4'>
          <a
            href='https://pancakeswap.finance/swap?outputCurrency=0x2bf4be7c4520c41d012eb09a034179e03b898534'
            className='partner-ship-link'
            target='_blank'
            rel='noopener noreferrer'
          >
            <img className='img-fluid' src={Pancake} alt='' />
          </a>
        </div>
      </div>
    </div>
  )
}

export default OurTeam
