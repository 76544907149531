import { useState } from 'react'
import TaxImage from '../../assets/tax-system.png'
import TokenomicImage from '../../assets/tokenomic.png'
import MenuTrigger from '../../components/Button/MenuTrigger'
import RoadMap from '../../components/RoadMap'
import './index.scss'

import CopyToClipboard from 'react-copy-to-clipboard'
import { AiOutlineCopy } from 'react-icons/ai'
import { BsInstagram, BsTwitter } from 'react-icons/bs'
import {
  FaDiscord,
  FaMediumM,
  FaRedditAlien,
  FaTelegramPlane,
  FaTiktok,
  FaYoutube
} from 'react-icons/fa'
import Team from '../../components/Team'
import DataRoadmap from '../../data/roadmap.json'

import Dextools from '../../assets/dextools.png'
// import Hotbit from '../../assets/hotbit.png'
import Indodax from '../../assets/indodax.png'
import Pancakeswap from '../../assets/pancakeswap.png'
import Cepek from '../../assets/cepek.png'
import Hermansyah from '../../assets/the-hermansyah.png'
import SolidityFinance from '../../assets/solidity-finance.png'
import Bappebti from '../../assets/bappebti.png'
import Partner from '../../components/Partner'
import ABIParter from '../../assets/assosiasi-blockchain-indonesia-partner.png'

const MainAsix = () => {
  const [openMenu, setOpenManu] = useState(false)
  const [isOpenWP, setIsOpenWP] = useState(true)
  const [copied, setCopied] = useState(false)

  return (
    <div className='page'>
      <MenuTrigger openMenu={openMenu} onClick={() => setOpenManu(!openMenu)} />
      <button onClick={() => setIsOpenWP(!isOpenWP)} className='btn-open-wp'>
        WHITEPAPER
      </button>
      <div className={`link-modal ${!isOpenWP ? '' : 'hide'}`}>
        <a
          onClick={() => setIsOpenWP(!isOpenWP)}
          href='https://asix-plus-2.gitbook.io/asix+-whitepaper-bahasa-indonesia'
          target='_blank'
          rel='noopener noreferrer'
          className='link-whitepaper'
        >
          INDONESIA
        </a>
        <a
          onClick={() => setIsOpenWP(!isOpenWP)}
          href='https://asix-plus-2.gitbook.io/asix+-whitepaper-english/'
          target='_blank'
          rel='noopener noreferrer'
          className='link-whitepaper'
          title='coming soon'
        >
          ENGLISH
        </a>
      </div>

      <div className='container-fluid'>
        <div className='content-top-main'>
          <div className='row justify-content-end'>
            <div className='col-lg-6 col-md-8'>
              <div className='infos-main p-4' data-aos='fade-left'>
                <div className='title'>The New Way</div>
                <h1 className='fitur-name'>Asix + </h1>
                <p className='desc-main'>
                  Asix+ is a token based on Binance Smart Chain (BEP20) network
                  which was launched in April 2022 PT. Meta Nusantara Viktori to
                  support it's project such as Pasar NFT and NUSANTARAVERSE.
                </p>
              </div>
              <div
                className='smart-contract text-center infos-main p-2 p-md-4 mt-2'
                data-aos='fade-left'
              >
                <div className={`smart-contract-copy`}>
                  <span className='title text-center text-white'>
                    Smart Contract Asix+
                  </span>
                  <div className='d-none d-sm-flex align-items-center justify-content-center'>
                    <p className={`${copied ? 'copied' : ''} text-center`}>
                      0x2bf4be7c4520c41d012eb09a034179e03b898534
                    </p>
                  </div>
                  <div className='d-flex d-sm-none align-items-center me-2'>
                    <p className={`${copied ? 'copied' : ''} small`}>
                      0x2bf4be7c4520c41d0...034179e03b898534
                    </p>
                  </div>
                  <CopyToClipboard text='0x2bf4be7c4520c41d012eb09a034179e03b898534'>
                    <button
                      onClick={() => setCopied(true)}
                      onBlur={() => setCopied(false)}
                      className='btn-copy ms-0 ms-md-2'
                    >
                      <AiOutlineCopy /> Copy
                    </button>
                  </CopyToClipboard>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='box-buy-on'>
        <a
          href='https://pancakeswap.finance/swap?outputCurrency=0x2bf4be7c4520c41d012eb09a034179e03b898534'
          target='_blank'
          rel='noopener noreferrer'
          className='item-link'
        >
          <span className='text-buy'>Pancakeswap</span>
          <img src={Pancakeswap} alt='pancakeswap' className='logo-buy' />
        </a>
        {/* <a
          href='https://www.hotbit.io/exchange?symbol=ASIXPLUS_nUSD'
          target='_blank'
          rel='noopener noreferrer'
          className='item-link'
        >
          <span className='text-buy'>Hotbit</span>
          <img src={Hotbit} alt='hotbit' className='logo-buy' />
        </a> */}
        <a
          href='https://www.dextools.io/app/bnb/pair-explorer/0xd059dca0ad8bc51803fd487e6ed8244c1fdad97e'
          target='_blank'
          rel='noopener noreferrer'
          className='item-link'
        >
          <span className='text-buy'>Dextools</span>
          <img src={Dextools} alt='Dextools' className='logo-buy' />
        </a>
        <a href='/#' rel='noopener noreferrer' className='item-link'>
          <span className='text-buy soon'>
            Indodax <small>comingsoon</small>
          </span>
          <img src={Indodax} alt='indodax' className='logo-buy' />
        </a>
      </div>
      <div className='main-asix-content'>
        <div className='container-fluid py-5'>
          <div className='row justify-content-between align-items-center'>
            <div className='col-lg-5 my-5'>
              <div className='left-box' data-aos='fade-down'>
                <h2 className='title'>Tax System</h2>
                <span>
                  ASIX+ Tax System is 4% Buy/Sell and will be allocated for:{' '}
                  <br />
                </span>
                <div className='row mt-2 text-white'>
                  <div className='col-2 col-md-2'>2.0% </div>
                  <div className='col'>Reflection</div>
                </div>
                <div className='row text-white'>
                  <div className='col-2 col-md-2'>0.5%</div>
                  <div className='col'>Charity</div>
                </div>
                <div className='row text-white'>
                  <div className='col-2 col-md-2'>1.5% </div>
                  <div className='col'>Developmnet</div>
                </div>
              </div>
            </div>
            <div className='col-lg-6 mb-5'>
              <div
                className='tax-image-wrapper d-flex justify-content-center'
                data-aos='fade-down'
              >
                <img className='img-fluid slide-bottom' src={TaxImage} alt='' />
              </div>
            </div>
          </div>
        </div>
        <div className='container-fluid py-5'>
          <div className='row justify-content-between align-items-center'>
            <div className='col-lg-5 mb-5'>
              <div className='left-box' data-aos='fade-right'>
                <h2 className='title'>Tokenomics</h2>
                <span>
                  ASIX+ Total Supply 10.000.000.000.000 Token and will be
                  allocated for :
                </span>
                <div className='row mt-2 text-white'>
                  <div className='col-3 col-md-2'>56.2% </div>
                  <div className='col'>Supply</div>
                </div>
                <div className='row text-white'>
                  <div className='col-3 col-md-2'>18.3%</div>
                  <div className='col'>Developer</div>
                </div>
                <div className='row text-white'>
                  <div className='col-3 col-md-2'>15.0% </div>
                  <div className='col'>R{`&`}D</div>
                </div>
                <div className='row text-white'>
                  <div className='col-3 col-md-2'>5.5% </div>
                  <div className='col'>Burn</div>
                </div>
                <div className='row text-white'>
                  <div className='col-3 col-md-2'>4.0% </div>
                  <div className='col'>Marketing Ops</div>
                </div>
                <div className='row text-white'>
                  <div className='col-3 col-md-2'>1.0% </div>
                  <div className='col'>Charity</div>
                </div>
              </div>
            </div>
            <div className='col-lg-6 mb-5'>
              <div
                className='tax-image-wrapper d-flex justify-content-center'
                data-aos='fade-up'
              >
                <img
                  className='img-fluid slide-bottom'
                  src={TokenomicImage}
                  alt=''
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id='roadmap'>
        <RoadMap data={DataRoadmap} />
      </div>
      <div id='team' className='py-5'>
        <h2 className='heading-roadmap'>Our Team</h2>
        <Team />
      </div>
      <div id='partner' className='py-5 bg-partner'>
        <h2 className='heading-roadmap'>Our Partner</h2>
        <div className='container'>
          <div className='row justify-content-center justify-content-md-between mb-5'>
            <div className='col-6 col-md-3'>
              <a
                href='https://asosiasiblockchain.co.id/#ourmember'
                target='_blank'
                rel='noopener noreferrer'
              >
                <img src={ABIParter} alt='cepek' className='img-fluid' />
              </a>
            </div>
            <div className='col-lg-2 d-md-none'></div>
            <div className='col-6 col-md-3'>
              <a
                href='https://instagram.com/thehermansyaha6?igshid=YmMyMTA2M2Y='
                target='_blank'
                rel='noopener noreferrer'
              >
                <img src={Hermansyah} alt='hermansayah' className='img-fluid' />
              </a>
            </div>
            <div className='col-lg-2 d-md-none'></div>
            <div className='col-6 col-md-3'>
              <a
                href='https://instagram.com/seratusentertainment?igshid=YmMyMTA2M2Y='
                target='_blank'
                rel='noopener noreferrer'
              >
                <img src={Cepek} alt='cepek' className='img-fluid' />
              </a>
            </div>
          </div>
        </div>
        <Partner />
      </div>
      <div className='container'>
        <div className='row justify-content-center'>
          <div className='col-12 col-lg-3 py-5'>
            <h3 className='title-audi text-white text-center'>
              Legalized by :{' '}
            </h3>
            <a
              href='https://bappebti.go.id/pbk/sk_kep_kepala_bappebti/detail/12673'
              target='_blank'
              rel='noopener noreferrer'
              className='link'
            >
              <img
                src={Bappebti}
                alt=''
                className='d-block img-fluid mx-auto rounded'
              />
            </a>
          </div>
          <div className='col-12 col-lg-3 py-5'>
            <h3 className='title-audi text-white text-center'>Audited by : </h3>
            <a
              href='https://solidity.finance/audits/ASIX/'
              target='_blank'
              rel='noopener noreferrer'
              className='link'
            >
              <img
                src={SolidityFinance}
                alt=''
                className='d-block img-fluid mx-auto'
              />
            </a>
          </div>
        </div>
      </div>
      <div className='social' id='social'>
        <a
          href='https://twitter.com/AsixPlusNFT'
          className='link-social'
          target='_blank'
          rel='noopener noreferrer'
        >
          <BsTwitter />
        </a>
        <a
          href='https://t.me/asixplusannouncement'
          className='link-social'
          target='_blank'
          rel='noopener noreferrer'
        >
          <FaTelegramPlane />
        </a>
        <a
          href='https://www.instagram.com/asixplus'
          className='link-social'
          target='_blank'
          rel='noopener noreferrer'
        >
          <BsInstagram />
        </a>
        <a
          href='https://vt.tiktok.com/ZSdQTdh4o/'
          className='link-social'
          target='_blank'
          rel='noopener noreferrer'
        >
          <FaTiktok />
        </a>
        <a
          href='https://www.reddit.com/user/AsixToken'
          className='link-social'
          target='_blank'
          rel='noopener noreferrer'
        >
          <FaRedditAlien />
        </a>
        <a
          href='https://discord.gg/hxZXefPK7t'
          className='link-social'
          target='_blank'
          rel='noopener noreferrer'
        >
          <FaDiscord />
        </a>
        <a
          href='https://medium.com/@AsixPlus'
          className='link-social'
          target='_blank'
          rel='noopener noreferrer'
        >
          <FaMediumM />
        </a>
        <a
          href='https://www.youtube.com/channel/UCBh3bGROYg3UOCvcp7UXHsg'
          className='link-social'
          target='_blank'
          rel='noopener noreferrer'
        >
          <FaYoutube />
        </a>
      </div>
      <div className='copyright'>
        <p>Copyright © 2022 Asix+ All Rights Reserved</p>
      </div>
    </div>
  )
}

export default MainAsix
