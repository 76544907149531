import React from 'react'
const PageNotFound = () => {
    return (
        <div className='pnf'>
            <div className="img-container">
                <img className='img-fluid' src="pnf.png" alt="pnf" />
            </div>
        </div>
    )
}

export default PageNotFound