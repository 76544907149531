import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import MenuTrigger from "../../components/Button/MenuTrigger";

const AsixWorld = () => {
  const [openMenu, setOpenManu] = useState(false);
  const navigate = useNavigate();
  return (
    <div className="page nusantara">
      <MenuTrigger openMenu={openMenu} onClick={() => setOpenManu(!openMenu)} />
      <div className="container-fluid">
        <div className="content-top">
          <div className="infos" data-aos="fade-left">
            <div className="title">Asix World</div>
            <h1 className="fitur-name">Nusantara Verse </h1>
            <p className="desc">
              Is a metaverse that will feature Indonesia in a digital version.
              Each region in Nusantara Verse has a digital version that will display
              the characteristics of each culture as well as the various famous
              landmarks they has Various digital assets such as land,
              buildings,characters and others in Nusantara Verse can be traded
              through the integrated pasar NFT platform. In addition, in Nusantara Verse users can meet each other to work, play game and watch
              virtual content together.
            </p>
            <div className="actions floating">
              <button
                onClick={() => navigate("/dev", { state: "nusantara-verse" })}
                className="btn-action-primary"
              >
                OPEN
              </button>
            </div>
          </div>
          <div className="copyright fix">
            <p>Copyright © 2022 Asix+ All Rights Reserved</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AsixWorld;
