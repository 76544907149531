import { AiFillFacebook, AiFillInstagram } from "react-icons/ai";
import { BsGlobe, BsInstagram, BsTwitter,BsArrowLeftShort } from "react-icons/bs";
import { FaDiscord, FaMediumM, FaRedditAlien, FaTelegramPlane, FaTiktok, FaYoutube } from "react-icons/fa";
import { Link, useLocation, useParams } from "react-router-dom";
import "./UnderDev.scss";

import MainAsix from "../../assets/main-asix.png";
import AsixMusic from "../../assets/a-plus.png";
import PasarNFT from "../../assets/pasar-nft.png";
import Earn from "../../assets/earn.png";
import AsixWorld from "../../assets/nusantara-verse.png";
const UnderDev = () => {

  const data = useLocation()
  const Logo = ()=>{
    if(data.state === "music"){
      return <img src={AsixMusic} alt="music" />
    }
    if(data.state === "pasar-nft"){
      return <img src={PasarNFT} alt="pasar-nft" />
    }
    if(data.state === "earn"){
      return <img src={Earn} alt="earn" />
    }
    if(data.state === "nusantara-verse"){
      return <img src={AsixWorld} alt="nusantara-verse" />
    }
    return <img src={MainAsix} alt="main-asix" />
  }


  return (
    <div className="page dev">
      <Link to="/" className="btn-back-home">
        <BsArrowLeftShort />
      </Link>
      <div className="wrapper">
        <div className={`logo ${data.state === "nusantara-verse" || data.state === "earn" ? "bigger" : "" }`}>
          <Logo />
        </div>
        <h1 className="title scale-up-center">COMING <br /> SOON</h1>
        {/* <Countdown
          className="text-white"
          renderer={renderer}
          date={data.state.time}
        /> */}
        {/* <div className="timer">
          <div className="time h">{days}</div>
          <div className="time h">{hours}</div>
          <div className="time m">{minutes}</div>
          <div className="time s">{second}</div>
        </div> */}
        <div className="dev-social">
          <div className="social" id="social">
            <a href="https://twitter.com/AsixPlusNFT" className="link-social" target="_blank" rel="noopener noreferrer">
              <BsTwitter />
            </a>
            <a href="https://t.me/asixplusannouncement" className="link-social" target="_blank" rel="noopener noreferrer">
              <FaTelegramPlane />
            </a>
            <a href="https://www.instagram.com/asixplus" className="link-social" target="_blank" rel="noopener noreferrer">
              <BsInstagram />
            </a>
            <a href="https://vt.tiktok.com/ZSdQTdh4o/" className="link-social" target="_blank" rel="noopener noreferrer">
              <FaTiktok />
            </a>
            <a href="https://www.reddit.com/user/AsixToken" className="link-social" target="_blank" rel="noopener noreferrer">
              <FaRedditAlien />
            </a>
            <a href="https://discord.gg/hxZXefPK7t" className="link-social" target="_blank" rel="noopener noreferrer">
              <FaDiscord />
            </a>
            <a href="https://medium.com/@AsixPlus" className="link-social" target="_blank" rel="noopener noreferrer">
              <FaMediumM />
            </a>
            <a href="https://www.youtube.com/channel/UCBh3bGROYg3UOCvcp7UXHsg" className="link-social" target="_blank" rel="noopener noreferrer">
              <FaYoutube />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UnderDev;
