import { HiMenu } from "react-icons/hi";
import { CgClose } from "react-icons/cg";
import { AiOutlineTeam } from "react-icons/ai";
import { SiGumroad } from "react-icons/si";
import { BiHome } from "react-icons/bi";
import { useLocation, useNavigate } from "react-router-dom";

const MenuTrigger = ({ onClick, openMenu }) => {
  const navigate = useNavigate()
  const pathLink = useLocation();
  const path = pathLink.pathname.replace("/", "");
  return (
    <div className="menu-trigger">
      {/* <button onClick={onClick}>
        {!openMenu ? (
          <HiMenu fontSize={30} />
        ) : (
          <CgClose fontSize={30} color="#ffd885" />
        )}
      </button> */}
      <button>
        <BiHome onClick={() => navigate("/")} fontSize={26} />
      </button>
      {
        path === "asix+" && (
          <>
            <a href="#roadmap" className="link">
              <SiGumroad />
            </a>
            <a href="#team" className="link">
              <AiOutlineTeam fontSize={26} />
            </a>
          </>
        )
      }

    </div>
  );
};

export default MenuTrigger;
