import React from 'react'
import { Navigation, Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

import ACONG from '../../assets/team/acong.png'
import ANANG from '../../assets/team/anang.png'
import AZRIEL from '../../assets/team/azriel.png'
import DANNY from '../../assets/team/danny.png'
import HARIS from '../../assets/team/haris.png'
import YoungLex from '../../assets/team/lex.png'

const OurTeam = () => {
  return (
    <>
      <div className='container'>
        <div className='row justify-content-center'>
          <div
            className='col-5 col-lg-3 px-4 px-md-0 image-team'
            data-aos='fade-down'
          >
            <a
              href='https://www.instagram.com/ananghijau/?hl=en'
              target='_blank'
              rel='noopener noreferrer'
            >
              <img src={ANANG} alt='' className='team-img img-fluid' />
            </a>
          </div>
          <div
            className='col-5 col-lg-3 px-4 px-md-0 image-team'
            data-aos='fade-down'
          >
            <a
              href='https://www.instagram.com/azriel_hermansyah/?hl=en'
              target='_blank'
              rel='noopener noreferrer'
            >
              <img src={AZRIEL} alt='' className='team-img img-fluid' />
            </a>
          </div>
        </div>
      </div>
      <div className='container'>
        <div className='row justify-content-center'>
          <div className='col-lg-9'>
            <Swiper
              hashNavigation={{
                watchState: true
              }}
              pagination={{
                clickable: true
              }}
              navigation={true}
              modules={[Pagination, Navigation]}
              spaceBetween={10}
              slidesPerView={3}
              onSlideChange={() => console.log('slide change')}
              onSwiper={swiper => console.log(swiper)}
              breakpoints={{
                640: {
                  slidesPerView: 2,
                  spaceBetween: 4
                },
                768: {
                  slidesPerView: 4,
                  spaceBetween: 5
                },
                1024: {
                  slidesPerView: 4,
                  spaceBetween: 5
                }
              }}
            >
              <SwiperSlide>
                <div className='image-team'>
                  <a
                    href='https://instagram.com/junymaimun?igshid=NmNmNjAwNzg='
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    <img src={ACONG} alt='' className='team-img img-fluid' />
                  </a>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className='image-team'>
                  <a
                    href='https://instagram.com/harismaulanaakbar?igshid=YmMyMTA2M2Y='
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    <img src={HARIS} alt='' className='team-img img-fluid' />
                  </a>
                </div>
              </SwiperSlide>

              <SwiperSlide>
                <div className='image-team'>
                  <a
                    href='https://instagram.com/young_lex18?igshid=NmNmNjAwNzg='
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    <img src={YoungLex} alt='' className='team-img img-fluid' />
                  </a>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className='image-team'>
                  <a
                    href='https://instagram.com/dannybaskara?igshid=YmMyMTA2M2Y='
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    <img src={DANNY} alt='' className='team-img img-fluid' />
                  </a>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </div>
    </>
  )
}

export default OurTeam
